
import { Options, Vue } from 'vue-class-component';
import TemplateForm from '@/components/TemplateForm.vue';
import FilesList from '@/components/FilesList.vue';

@Options({
  components: {
    TemplateForm, FilesList,
  },
})
export default class Settings extends Vue {}
