export const emailConvert = (value: string[]) => value.join(', ');

export const dateConvert = (value: string) => new Date(value).toLocaleTimeString([], {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const dateSort = (a: string, b: string) => new Date(a).valueOf() - new Date(b).valueOf();

export const getFileName = (fileName: string) => fileName.split('.').slice(0, -1).join('.');
