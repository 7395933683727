import axiosInstance from '@/helpers/axiosInstance';
import { TemplateTypes } from '@/types/TemplateTypes';

export const getTemplate = async () => {
  const { data } = await axiosInstance.get<TemplateTypes>('/template');

  return data;
};

export const updateTemplate = async (payload: TemplateTypes) => {
  const { data } = await axiosInstance.put<TemplateTypes>('/template', payload);

  return data;
};
