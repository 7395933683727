import axiosInstance from '@/helpers/axiosInstance';
import { HistoryTableTypes, HistoryUpdatePayloadTypes } from '@/types/HistoryTypes';

export const getHistoryRequests = async () => {
  const { data } = await axiosInstance.get<HistoryTableTypes[]>('/history');

  return data;
};

export const updateHistoryRequests = async (payload: HistoryUpdatePayloadTypes, id: string) => {
  const { data } = await axiosInstance.put<HistoryTableTypes>(`/history/${id}`, payload);

  return data;
};
