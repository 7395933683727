
import { Options, Vue } from 'vue-class-component';
import SendingEmail from '@/components/SendingEmail.vue';

@Options({
  components: {
    SendingEmail,
  },
})
export default class Home extends Vue {}
