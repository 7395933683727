import { createApp } from 'vue';
import 'element-plus/dist/index.css';
import 'element-tiptap/lib/style.css';
import 'element-plus/theme-chalk/display.css';

import ElementPlus from 'element-plus';
import { ElementTiptapPlugin } from 'element-tiptap';
import App from './App.vue';
import router from './router';
import store from './store';

const app = createApp(App);

app.use(store).use(router).use(ElementPlus).use(ElementTiptapPlugin)
  .mount('#app');
