
import { Options, Vue } from 'vue-class-component';
import List from '@/components/MailingListArchive.vue';

@Options({
  components: {
    List,
  },
})
export default class EmailHistory extends Vue {}
