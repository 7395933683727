import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Settings from '@/views/Settings.vue';
import Home from '../views/Home.vue';
import EmailHistory from '../views/EmailHistory.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'About - MyApp' },
  },
  {
    path: '/history',
    name: 'EmailHistory',
    component: EmailHistory,
  },
  {
    path: '/setting',
    name: 'Settings',
    component: Settings,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
