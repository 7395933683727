import { DocsResponseTypes, DocsTypes, DocsUploadPayloadTypes } from '@/types/DocsTypes';
import axiosInstance from '@/helpers/axiosInstance';

export const getDocuments = async () => {
  const { data } = await axiosInstance.get<DocsTypes[]>('/docs');

  return data;
};

export const uploadDocument = async (payload: DocsUploadPayloadTypes) => {
  const { data } = await axiosInstance.post<DocsTypes>('/upload', payload);

  return data;
};

export const deleteDocument = async (id: string) => {
  const { data } = await axiosInstance.delete<DocsResponseTypes>(`/upload/${id}`);

  return data;
};
